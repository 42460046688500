;(function( window, document, $, undefined ) {
  'use strict';

  var home = (function() {

    var $private = {};
    var $public = {};
    var screen_width = $(window).width();
    var mainSlider;
    var indexSlide = 0;
    var currentSlide = 0;

    var goToMap = function(data) {
      $('.dd-map-hidden:not(#'+data+')').hide();
      $('#' + data).fadeIn('fast');
    }

    $public.homeSlider = function() {
      var mainSlider = $('.dd-main-bxslider').bxSlider({
        controls: false,
        pager: true,
        pause: 5000,
        onSlideAfter: function() {
          mainSlider.stopAuto();
          mainSlider.startAuto();
        },
        onSliderLoad : function() {
          $('.dd-section-main-slider').css('visibility' , 'visible');
          if($(".dd-main-bxslider li").length <= 3) {
            $('.dd-arrows-banner').hide();
            $('.bx-controls').hide();
          }else {
            mainSlider.startAuto();
          }
        }
      });

      $('.dd-arrows-banner .dd-arrow-left').on('click', function(){
        mainSlider.goToPrevSlide();
      });

      $('.dd-arrows-banner .dd-arrow-right').on('click', function(){
        mainSlider.goToNextSlide();
      });
    }

    $public.bannerRequest = function(){
      var element = $('#dd-banner-home');

      if (element)
      {
        var uri = element.attr('data-uri');
        $.get( uri, function( data ){
          element.html(data);
          var itens = element.find('ul li').length;
          if (itens > 0) {
            $('.dd-slide-home').fadeIn('slow');
            home.homeSlider();
          } else {
            element.remove();
          }
        });
      }
    }

    $public.localSlider = function() {
      mainSlider = $('.dd-slider-local').bxSlider({
        controls: false,
        pager: false,
        slideMargin: 20,

        infiniteLoop: false,
        hideControlOnEnd: true,

        onSliderLoad : function() {
          $('.dd-slider-local').css('visibility' , 'visible');
          $('.dd-c-slider-local').css('visibility' , 'visible');

          if($(".dd-slider-local li").length <= 1) {
            $('.dd-section-local .dd-small-arrow-slider').addClass('dd-hide');
          }

          $private.map();
        },
        onSlideAfter: function(){
          if (indexSlide != currentSlide) {
            mainSlider.goToSlide(indexSlide);
          }
        }
      });

      if (indexSlide == 0) {
        $('.dd-section-local .dd-arrow-left').addClass('dd-disabled');
      }

      $('.dd-section-local .dd-arrow-left').on('click', function(){

        if (indexSlide <= mainSlider.getSlideCount()) {
          $('.dd-section-local .dd-arrow-right').removeClass('dd-disabled');
        }

        if (indexSlide == 0) {
          return false;
        } else if (indexSlide <= 1) {
          $(this).addClass('dd-disabled');
        } else {
          $(this).removeClass('dd-disabled');
        }

        indexSlide--;
        var img = $('.dd-slider-local li').eq(indexSlide).attr('data-item');
        goToMap(img);
        mainSlider.goToSlide(indexSlide);
      });

      $('.dd-section-local .dd-arrow-right').on('click', function(){
        if (indexSlide >= 0) {
          $('.dd-section-local .dd-arrow-left').removeClass('dd-disabled');
        }

        if (indexSlide == (mainSlider.getSlideCount()-1)) {
          return false;
        } else if (indexSlide >= (mainSlider.getSlideCount()-2)) {
          $(this).addClass('dd-disabled');
        }

        indexSlide++;
        var img = $('.dd-slider-local li').eq(indexSlide).attr('data-item');
        goToMap(img);
        mainSlider.goToSlide(indexSlide);
      });
    }

    $private.map = function(){
      // Map responsivo
      $('img[usemap]').rwdImageMaps();

      // Ao clicar em um item do mapa habilitar os botões de controle e;
      // descer a página até o slider
      $('#dd-image-maps area').click(function(){

        $('.dd-section-local .dd-arrow-left').removeClass('dd-disabled');
        $('.dd-section-local .dd-arrow-right').removeClass('dd-disabled');

        var aTag = $(".dd-c-slider-local");
        $('html,body').animate({scrollTop: aTag.offset().top},'slow');
      });

      // Hover
      $(document).on('mouseover', '#dd-image-maps area', function() {
        var data_id = $(this).attr('data-id');
        goToMap(data_id);
        // indexSlide = $('li[data-item='+data_id+']').index();
        // currentSlide = mainSlider.getCurrentSlide();
        // mainSlider.goToSlide(indexSlide);
      });

      // Click
      $(document).on('click', '#dd-image-maps area', function() {
        var data_id = $(this).attr('data-id');
        indexSlide = $('li[data-item='+data_id+']').index();
        currentSlide = mainSlider.getCurrentSlide();
        mainSlider.goToSlide(indexSlide);
      });
    }

    return $public;
  })();

  // Global
  window.home = home;
  home.bannerRequest();
  //home.homeSlider();
  home.localSlider();


})( window, document, jQuery );

$(window).load(function() {
});
